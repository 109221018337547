exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-index-jsx": () => import("./../../../src/pages/about-us/index.jsx" /* webpackChunkName: "component---src-pages-about-us-index-jsx" */),
  "component---src-pages-about-us-our-team-index-jsx": () => import("./../../../src/pages/about-us/our-team/index.jsx" /* webpackChunkName: "component---src-pages-about-us-our-team-index-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-industry-insights-jsx": () => import("./../../../src/pages/industry-insights.jsx" /* webpackChunkName: "component---src-pages-industry-insights-jsx" */),
  "component---src-pages-publications-jsx": () => import("./../../../src/pages/publications.jsx" /* webpackChunkName: "component---src-pages-publications-jsx" */),
  "component---src-templates-blog-detail-jsx": () => import("./../../../src/templates/blog-detail.jsx" /* webpackChunkName: "component---src-templates-blog-detail-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */)
}

